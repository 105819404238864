import React, {useEffect, useState} from 'react'

const DefaultMessage = ({validationChecks, confirm}) => {
    // Default message that needs to be displayed to tell the user that
    // there are fields that is required to be filled in.
    const [displayMessage, setDisplayMessage] = useState(false);

    const message = (
        <p 
            style={{color:'red', 
            fontSize: '20px', 
            display: 'block', 
            fontWeight: 'bold'}}
        >
                There are mandatory fields in this form, which contain required 
                information that you have not provided. You must complete these sections 
                to be able to submit this form. Please review the form and complete all of 
                the indicated fields.
        </p>
    )

    useEffect(()=>{
        // When form is first loaded and required fields are all empty,
        // default message shouldn't be displayed.
        if(validationChecks[0][1][1] === '' && validationChecks[4][1][1] === ''){
            setDisplayMessage(false);
        } else {
            if(confirm === false) {
                setDisplayMessage(true);
            } else {
                setDisplayMessage(false);
            }
        }
    },[validationChecks, confirm])

    return (
        <>
            {displayMessage ? message : <></>}        
        </>
    )
}

export default DefaultMessage;