import './css/styles.css';
import './App.css';
import {
  Routes,
  Route
} from 'react-router-dom';
import ReferralForm     from '../referralForm/ReferralForm';
import Option           from '../option/Option';
import Payment          from '../payment/Payment';
import Referral         from '../referral/Referral';
import Feedback         from '../feedback/Feedback.jsx';
import Return           from '../return/Return';
import DefaultPage      from './DefaultPage';
import ReferralFormHeader from '../layout/headers/ReferralFormHeader';
import ReferralFormFooter from '../layout/footers/ReferralFormFooter';
import OptionHeader from '../layout/headers/OptionHeader';
import OptionFooter from '../layout/footers/OptionFooter';
import DefaultHeader from '../layout/headers/DefaultHeader';
// import DefaultFooter from '../layout/footers/DefaultFooter';

const AppRoutes = ({ api, tokenContent, brandingInfo }) => {

    return (
        <div className="App">
            
                <Routes>
                    <Route path="/ReferralForm"         element={<ReferralForm      api={api} tokenContent={ tokenContent } brandingInfo={ brandingInfo }/>} />
                    <Route path="/Option"               element={<Option            api={api} tokenContent={ tokenContent } brandingInfo={ brandingInfo }/>} />
                    <Route path="/Payment"              element={<Payment           api={api} tokenContent={ tokenContent } brandingInfo={ brandingInfo }/>} />
                    <Route path="/Referral"             element={<Referral          api={api} tokenContent={ tokenContent } brandingInfo={ brandingInfo }/>} />
                    <Route path="/Feedback"             element={<Feedback          api={api} tokenContent={ tokenContent } brandingInfo={ brandingInfo }/>} />
                    <Route path="/Return/*"             element={<Return            api={api} tokenContent={ tokenContent } brandingInfo={ brandingInfo }/>} />
                    <Route path="/*"                    element={<DefaultPage       api={api} tokenContent={ tokenContent } />} />
                </Routes>
           
        </div>
    );
};

const AppHeader = ({brandingInfo}) => {
    return (
        <div>
                <Routes>
                    <Route path="/ReferralForm"         element={<ReferralFormHeader />} />
                    <Route path="/Option"               element={<OptionHeader  brandingInfo={ brandingInfo }/>} />
                    <Route path="/Payment"              element={<DefaultHeader brandingInfo={ brandingInfo }/>} />
                    <Route path="/Referral"             element={<OptionHeader  brandingInfo={ brandingInfo }/>} />
                    <Route path="/Feedback"             element={<OptionHeader  brandingInfo={ brandingInfo }/>} />
                    <Route path="/Return/*"             element={<OptionHeader  brandingInfo={ brandingInfo }/>} />
                    <Route path="/*"                    element={<OptionHeader  brandingInfo={ brandingInfo }/>} />
                </Routes>
        </div>
    );
};

const AppFooter = () => {
    return (
        <div>
                <Routes>
                    <Route path="/ReferralForm"         element={<ReferralFormFooter />} />
                    <Route path="/Option"               element={<OptionFooter />} />
                    <Route path="/Referral"             element={<OptionFooter />} />
                    <Route path="/Feedback"             element={<OptionFooter />} />
                    <Route path="/Return/*"             element={<OptionFooter  />} />
                    <Route path="/Payment"              element={<OptionFooter />} />
                    <Route path="/*"                    element={<OptionFooter />} />
                </Routes>
        </div>
    );
};

export { AppRoutes, AppFooter, AppHeader };