import React, {useEffect, useState} from 'react'
var url = window.location.href.replace(/\/$/, '');
var uid = url.substr(url.lastIndexOf('/') + 1);

const Return = ({ api }) => {
    const [uniqueId, setUniqueId] = useState(null)

    useEffect(()=>{
        if(uid !== null){
            setUniqueId(uid)     
        }
    },[])

    useEffect(()=>{
        const params = {
            'uid': uniqueId
        }
        if(uniqueId !== null){
            api.transact('GetWelcomeTokenFromUID', params)
                .then(response => {
                    console.log('response: ', response);
                    if(response.apiResult){
                        api.setToken(response.apiResult.Token);
                        window.location.href = response.apiResult.NextURL;
                    }
                })
                .catch(e=>{
                    console.log(e);
                })
        }
    },[uniqueId, api])

    return (
        <>
            <div className="container">
                <div className="typography">
                    <div
                        style={{
                            paddingTop: '55px',
                            marginBottom: '88px',
                            fontSize: '1.8rem',
                            width: 'auto'
                        }}
                    >
                        <svg width="118" height="118" viewBox="0 0 118 118">
                            <defs>
                            <clipPath id="clip-path">
                                <rect id="Rectangle_90" data-name="Rectangle 90" width="76.471" height="77.221" transform="translate(0 0)" fill="none" stroke="#8db147" strokeWidth="1"></rect>
                            </clipPath>
                            </defs>
                            <circle id="Ellipse_27" data-name="Ellipse 27" cx="59" cy="59" r="59" fill="#f0f0f0"></circle>
                            <g id="Group_365" data-name="Group 365" transform="translate(20.765 20.389)">
                            <g id="Group_364" data-name="Group 364" clipPath="url(#clip-path)">
                            <path id="Path_267" data-name="Path 267" d="M31.2,31.134A17.373,17.373,0,1,1,6.574,6.619a17.387,17.387,0,0,1,29.7,12.258A17.24,17.24,0,0,1,31.2,31.134Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_268" data-name="Path 268" d="M27.588,21.789a31.622,31.622,0,0,0-18.072,0,4.749,4.749,0,0,0-3.058,4.224v5.006a17.413,17.413,0,0,0,5.558,3.827v0A17.3,17.3,0,0,0,25.09,35.1v0a17.3,17.3,0,0,0,5.556-3.466V26.013A4.738,4.738,0,0,0,27.588,21.789Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_269" data-name="Path 269" d="M18.552,7.971A6.145,6.145,0,1,0,24.7,14.116,6.145,6.145,0,0,0,18.552,7.971Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_270" data-name="Path 270" d="M69.9,70.6a17.34,17.34,0,1,1,5.072-12.257A17.268,17.268,0,0,1,69.9,70.6Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_271" data-name="Path 271" d="M66.289,61.25a31.622,31.622,0,0,0-18.072,0,4.749,4.749,0,0,0-3.058,4.224V70.48a17.394,17.394,0,0,0,5.558,3.826v0a17.29,17.29,0,0,0,13.074.258v0A17.3,17.3,0,0,0,69.347,71.1V65.474A4.738,4.738,0,0,0,66.289,61.25Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_272" data-name="Path 272" d="M57.253,47.431A6.145,6.145,0,1,0,63.4,53.576,6.145,6.145,0,0,0,57.253,47.431Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_273" data-name="Path 273" d="M70.261,31.394A25.491,25.491,0,0,0,44.554,7.41" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            <path id="Path_274" data-name="Path 274" d="M7.4,44.378a25.489,25.489,0,0,0,24.86,24.86" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            <path id="Path_275" data-name="Path 275" d="M3.256,48.521,7.4,44.378l4.144,4.143" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            <path id="Path_276" data-name="Path 276" d="M73.894,27.6l-4.143,4.143L65.607,27.6" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            </g>
                            </g>
                        </svg>
                        <h1
                            style={{
                                paddingTop: '40px',
                                fontSize: '2rem'
                            }}
                        >
                            Please wait...
                        </h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Return