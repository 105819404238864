import React, {useEffect, useState} from 'react'

const ExpireTimer = ({expireTime, setExpiryStatus, formMessage}) => {

    const [currentTime, setCurrentTime] = useState(new Date().getTime() / 1000);
    const [remainingTime, setRemainingTime] = useState('');

    // Counter that triggers every 1 sec and sets currentTime state
    useEffect(()=>{
        const interval = setInterval(()=>{
            setCurrentTime(Math.floor(new Date().getTime() / 1000));
        }, 1000)
        return () => clearInterval(interval);
    })

    // When expireTime and currentTime state changes,
    // new remainingTime state will be set
    useEffect(()=>{
        let timeValue = 60*30;
        if(expireTime!==''){
            timeValue = expireTime - currentTime;
            setRemainingTime(timeValue);
        }
    }, [expireTime, currentTime])

    // Function triggers when remainingTime changes
    // to check and changes state of expiry status 
    // by using setExpiryStatus passed down as a prop
    useEffect(()=>{
        if(expireTime !== ''){
            if(remainingTime < 300 && remainingTime > 0){
                setExpiryStatus('<5mins');
            } else if(expireTime === ''){
                setExpiryStatus('checking');
            } else if(remainingTime <= 0 && expireTime !== '') {
                setExpiryStatus('expired');
            } else {
                setExpiryStatus('valid');
            }
        }
    },[remainingTime])

    return (
        <div>
            <p id="warning">{formMessage}</p>
        </div>
    )
}

export default ExpireTimer;
