import React from 'react'

const ErrorMessage = ({tokenValidity, err, styles}) => {
    
    const errorMessage = err

    const errorMessageStyle = {
        fontSize: '1em',
        textAlign: 'left',
        margin: '20px 0px'
    }

    return (
    <>
        <div style={styles.container} className='container'>
            { tokenValidity !== true ? (<p className="payment errorMessage" style={errorMessageStyle}>Application Error: Token Expired.</p>) : (<></>) }
            { errorMessage !== 'No Error' ? (<p className="payment errorMessage" style={errorMessageStyle}>Application Error: {errorMessage}</p>) : (<></>) }
        </div>
    </>
  )
}

export default ErrorMessage