/**
 * Consts for Services.
 * These are all enum TreatmentPathwayType in the API
 */

const    BookedTCAService= "44";
const    DigitalTriageService= "45";
const    ImmediateTCAService= "46";
const    MedicalReferralService= "47";
const    MedReferralChooseAndBookService= "54";

export { BookedTCAService, DigitalTriageService, ImmediateTCAService, 
    MedicalReferralService, MedReferralChooseAndBookService };
