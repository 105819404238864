import React from 'react'

const ClinicAddress = ({
        api, 
        selectedClinic, 
        setSearchAgain,
        selectClinic,
        confirmClinicErrorMessage}) => {

    const searchAgainClick = () => {
        // console.log('clicked search again')
        setSearchAgain(true)
    }

    // const apiMakeReferral = (token, facilityId) => {
    //     const params = {
    //         "Token": token,
    //         "FacilityID" : facilityId
    //     }
    //     api.transact("MakeReferral", params)
    //         .then(r => {
    //             window.location.replace('../feedback')
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }

    // const selectClinic = () => {
    //     // console.log(selectedClinic.FacilityID);
    //     // console.log(api.getToken());

    //     apiMakeReferral(api.getToken(), selectedClinic.FacilityID);
    // }

    const buttonStyle = {
        fontSize: '17px',
        width: '206px',
        border: 'none',
        cursor: 'pointer',
        padding: '8px 12px',
        transition: 'all 200ms ease-in-out'
    }

    return (
        <div className="container">
            <div className="typography">
                <h1>
                    {selectedClinic.FacilityName}
                </h1>
                <p>
                    {selectedClinic.Address1 !== null ? selectedClinic.Address1 + ', ' : ''} 
                    {selectedClinic.Address2 !== null ? selectedClinic.Address2 + ', ' : ''}
                    {selectedClinic.Address3 !== null ? selectedClinic.Address3 + ', ' : ''}
                    {selectedClinic.Postcode !== null ? selectedClinic.Postcode : ''}
                </p>
                <br />
                <br />
                <div className="clinicAddressButtons">
                    <button 
                        href="#" 
                        className="green-button selectClinicButton"
                        style={buttonStyle}
                        onClick={(e)=>selectClinic(e, selectedClinic.FacilityID)}
                    >
                        Select Clinic
                    </button>
                    <button 
                        href="#" 
                        className="green-button selectClinicButton"
                        style={buttonStyle}
                        onClick={()=>searchAgainClick()}
                    >
                        Search Again
                    </button>
                </div>
                <div>
                    <p class="confirmClinicPopupErrorMessage">{confirmClinicErrorMessage}</p>
                </div>
                <br />
                <br />
            </div>
        </div>
  )
}

export default ClinicAddress