import  React               from 'react';

const DefaultPage = ({ }) => {

    return <div>
        You should have arrived at this website via a specific link 
        from another site or via a link in an email. 
    </div>
};

export default DefaultPage;