import React from 'react';
import Check from '../../images/xIcon.svg';
import PhoneNumber from '../PhoneNumber';

const PaymentUnsuccessful = ({setPaymentResult, brandingInfo}) => {

    const resetPaymentResult = () => {
        setPaymentResult('')
    }

    return (
        <form>
            <div 
                className='form__group paymentMessage'
                style={{
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center'
                }}
            >
                <img 
                    src={Check} 
                    alt='Check icon' 
                    style={{
                        width: '100px',
                        margin: 'auto',
                    }}
                />
                <div className="form__title">
                    <h2 style={{
                        color: '#B8397A',
                        margin: '0.75em 0',
                        fontWeight: '600',
                        fontSize: '150%'
                    }}>
                        Your payment card was not authenticated
                    </h2>
                </div>
                <p
                    className='typography'
                    style={{
                        paddingBottom: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }}
                >
                    Thank you, but unfortunately your payment card was unsuccessful in being authenticated. Please try again.
                </p>
                <button
                    type="button" 
                    className="payment form__action" 
                    onClick={resetPaymentResult}
                    style={{
                        margin: 'auto',
                        height: '42px'
                    }}
                >
                    Try Again
                </button>
                <p
                    className='typography'
                    style={{
                        marginTop: '20px',
                        paddingBottom: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }}
                >
                    Having trouble? If you cannot complete this step then please call 
                    IPRS Health for further assistance on <PhoneNumber brandingInfo={brandingInfo}/>, 
                    {brandingInfo?.PhoneOpeningHours?? "Monday to Friday 9am - 5pm"}
                </p>
            </div>
        </form>
    )
}

export default PaymentUnsuccessful