import React from 'react'

const ReferralFormFooter = () => {
  return (
    <footer className="footer">
        <div className="footer__content group">
            <div className="container">
                <div className="footer__left">
                    <p>Part of</p>
                    <a href="http://www.iprsgroup.com/" target="_blank" rel="noreferrer" className="footer__logo replace">IPRS Group</a>
                </div>
                <div className="footer__right">
                    <p><a href="https://www.iprshealth.com/cookie-policy/" rel="noreferrer" target="_blank">Privacy &amp; Cookies</a></p>
                    <br /><br /><br />
                    <p><small>&copy; 2019-2023 IPRS Health Limited, Speed Medical House, Chorley, Lancashire PR7 1NA. Registered in England and Wales (03015659).</small></p>
                    <p><small>Website design: <a href="http://www.nakedmarketing.co.uk/" rel="noreferrer" target="_blank">Naked Marketing</a></small></p>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default ReferralFormFooter