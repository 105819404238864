import React, {useState, useEffect} from 'react';
import { BookedTCAService, DigitalTriageService, ImmediateTCAService, 
    MedicalReferralService, MedReferralChooseAndBookService } from './ServiceConst';

const ConfirmCheckBox = ({service, checkBox, setCheckBox, clientID}) => {

    // Check box requirement state that changes when service state changes
    // which happens when user clicks one of an option in the drop down select options.
    const [checkBoxRequirement, setCheckBoxRequirement] = useState([
        [DigitalTriageService, false],
        [ImmediateTCAService, false],
        [MedicalReferralService, false],
        [MedReferralChooseAndBookService, false]
    ])

    // Store state of each checkboxes boolean value.
    const [checkBoxStatus, setCheckBoxStatus] = useState([
        [DigitalTriageService, false],
        [ImmediateTCAService, false],
        [MedicalReferralService, false],
        [MedReferralChooseAndBookService, false]
    ]);

    // 
    const [caseStyle, setCaseStyle] = useState([
        {display: 'none'},
        {display: 'none'},
        {display: 'none'}
    ]);

    // Function that executes when one of the checkbox is clicked.
    const checkBoxSwitch = (e) => {
        const tick = e.target.checked;
        checkBoxStatusTicker(tick);
    }

    const checkBoxStatusTicker = (tick) => {
        switch(service){
            case DigitalTriageService:
                setCheckBoxStatus([
                    [DigitalTriageService, tick ? true : false],
                    [ImmediateTCAService, false ],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, false]
                ]);
                break;
            case ImmediateTCAService:
                setCheckBoxStatus([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, tick ? true : false],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, false]
                ])
                break;
            case MedicalReferralService:
                setCheckBoxStatus([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, tick ? true : false],
                    [MedReferralChooseAndBookService, false]
                ])
                break;
            case MedReferralChooseAndBookService:
                setCheckBoxStatus([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, tick ? true : false]
                ])
                break;
            default:
                setCheckBoxStatus([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, false]
                ])
                break;
        }
        console.log('after: ', tick);
    }

    // // Checks which check box has been ticked and monitors which service option is selected
    // // Based on the state of service and checkBoxStatus, setCheckBox state will be set
    // // and it will be passed to the parent component which is used to determine whether
    // // to have Confirm button disabled or undisabled.
    useEffect(()=>{
        switch(service){
            case BookedTCAService:
                setCheckBox(true);
                break;
            case DigitalTriageService:
                if(checkBoxStatus[0][1] && checkBoxRequirement[0][1]){
                    setCheckBox(true);
                } else {
                    setCheckBox(false);
                }
                break;
            case ImmediateTCAService:
                if(checkBoxStatus[1][1] && checkBoxRequirement[1][1]){
                    setCheckBox(true);
                } else {
                    setCheckBox(false);
                }
                break;
            case MedicalReferralService:
                if(checkBoxStatus[2][1] && checkBoxRequirement[2][1]){
                    setCheckBox(true);
                } else {
                    setCheckBox(false);
                }
                break;
            default:
                setCheckBox(false);
        }
    }, [service, checkBoxStatus, checkBoxRequirement])

    const avivaClientID = 349910;
    const InformationList = ({ clientID }) => {

        const userIDISAvivaClintID = (clientID === avivaClientID);

        return userIDISAvivaClintID ?
        <ul>
            <li>Informed the member that Digital Rehab should only be used for one injury or area of pain/discomfort</li>
            <li>
                Informed the member that the Digital Assessment does not work in older browsers including Internet Explorer
            </li>
            <li>Informed the member that the link for Digital Rehab will expire after 14 days</li>
        </ul>
        :
        <ul>
            <li>Informed the member that Digital Rehab should only be used for one injury or area of pain/discomfort</li>
            <li>Advised the member of any relevant T&C’s including excess or limits</li>
            <li>
                Advised the member that if they require F2F or Virtual Physio at the end of the Digital Assessment that they will be asked to authenticate payment card details
                (NB: This is the last opportunity to remind the member of their excess should Physiotherapy be recommended at the end of the Digital Assessment)
            </li>
            <li>
                Informed the member that the Digital Assessment does not work in older browsers including Internet Explorer
            </li>
            <li>Informed the member that the link for Digital Rehab will expire after 14 days</li>
        </ul>


    }

    const caseCheckbox = (
        <>
            <div id='case45' style={caseStyle[0]}>
                <div className="form__field">
                    <div>
                        <label id="referralFormCheckBoxLabel">Confirm text and/or email sent to member with digital link providing access to digital landing page</label>
                    </div>
                    <div className="referralFormCheckBox">
                        <input 
                            type="checkbox"
                            style={{cursor: "pointer"}} 
                            defaultChecked={checkBoxStatus[0][1]} 
                            onInput={checkBoxSwitch}  
                        /> 
                    </div>
                </div>

                <div>
                    <p>
                        Before continuing, please confirm you have:
                    </p>

                    <InformationList clientID={clientID} />
                </div>
            </div>
            <div id="case46" style={caseStyle[1]}>
                <div className="form__field">
                    <div>
                        <label id="referralFormCheckBoxLabel">Confirm that member has been handed over for { clientID !== 349910 ? 'immediate TCA' : 'warm transfer'}</label>
                    </div>

                    <div className="referralFormCheckBox">                    
                        <input 
                            type="checkbox" 
                            style={{cursor: "pointer"}} 
                            defaultChecked={checkBoxStatus[1][1]} 
                            onInput={checkBoxSwitch} 
                        />
                    </div>
                </div>
            </div>
            <div id="case47" style={caseStyle[2]}>
                <div className="form__field">
                    <div>
                        <label id="referralFormCheckBoxLabel">Confirm that member has been handed over to book Physio</label>
                    </div>
                    <div className="referralFormCheckBox">
                        <input 
                            type="checkbox"
                            style={{cursor: "pointer"}} 
                            defaultChecked={checkBoxStatus[2][1]} 
                            onInput={checkBoxSwitch} 
                        />
                    </div>
                </div>
            </div>
        </>
    )



    // Changes the checkbox div's style based on service selected and also changes
    // state of checkBoxRequirements accordingly.
    useEffect(()=>{
        switch(service){
            case BookedTCAService:
                setCaseStyle([
                    {display: 'none'},
                    {display: 'none'},
                    {display: 'none'}
                ]);
                setCheckBoxRequirement([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, false]
                ]);
                break;
            case DigitalTriageService:
                setCaseStyle([
                    {display: 'block'},
                    {display: 'none'},
                    {display: 'none'}
                ])
                setCheckBoxRequirement([
                    [DigitalTriageService, true],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, false]
                ])
                break;
            case ImmediateTCAService:
                setCaseStyle([
                    {display: 'none'},
                    {display: 'block'},
                    {display: 'none'}
                ])
                setCheckBoxRequirement([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, true],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, false]
                ])
                break;
            case MedicalReferralService:
                setCaseStyle([
                    {display: 'none'},
                    {display: 'none'},
                    {display: 'block'}
                ])
                setCheckBoxRequirement([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, true],
                    [MedReferralChooseAndBookService, false]
                ])
                break;
            case MedReferralChooseAndBookService:
                setCaseStyle([
                    {display: 'none'},
                    {display: 'none'},
                    {display: 'none'}
                ])
                setCheckBoxRequirement([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, true]
                ])
                break;
            default:
                setCaseStyle([
                    {display: 'none'},
                    {display: 'none'},
                    {display: 'none'}
                ])
                setCheckBoxRequirement([
                    [DigitalTriageService, false],
                    [ImmediateTCAService, false],
                    [MedicalReferralService, false],
                    [MedReferralChooseAndBookService, false]
                ])
                break;
        }
    }, [service])

    return (
        <>
            {caseCheckbox}
        </>
    )
}

export default ConfirmCheckBox;