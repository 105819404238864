const BrandKey_Payment_Cost = "Payment Cost";
// Needs to be a boolean value
const BrandingKey_Show_Feedback_Stars_On_SelfReferral =
    "Show Feedback Stars On SelfReferral";
// Needs to a text value
const BrandingKey_Feedback_Stars_Label_Text = "Feedback Stars Label Text";

export {
    BrandKey_Payment_Cost,
    BrandingKey_Show_Feedback_Stars_On_SelfReferral,
    BrandingKey_Feedback_Stars_Label_Text
};
