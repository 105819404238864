import React, {useEffect} from 'react';
import avivaLogo from '../../../images/logos/aviva-logo.svg';
import iprsLogo from '../../../images/logos/iprs-logo.svg';

const OptionHeader = ({brandingInfo}) => {
    return (
        <header className="header">
            <div className="container">
                <div className="header__inner">
                    <div className="logo logo--aviva">
                        {brandingInfo?.BrandLogo !== undefined && brandingInfo.BrandLogo === 'https://av-mylogo.invalid' ? 
                            <img src={avivaLogo} alt="Aviva Logo" /> : 
                            <></>
                        }
                        {/* <img src={avivaLogo} alt="Aviva Logo" /> */}
                    </div>
                    <div className="logo logo--iprs">
                        <img src={iprsLogo} alt="IPRS Logo" />
                    </div>
                </div>
                <p className="header__p">Your recovery starts here</p>
            </div>
        </header>
    )
}

export default OptionHeader;