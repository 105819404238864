import React, {useEffect, useState} from 'react';
import avivaLogo from '../../../images/logos/aviva-logo.svg';
import iprsLogo from '../../../images/logos/iprs-logo.svg';

const DefaultHeader = ({brandingInfo}) => {
    const styles = {
        container: {maxWidth: '960px'},
        header: {borderBottom: '0px'},
        header__inner: {padding: '20px', borderBottom: '1px solid #d5579b'},
        header__p: {fontSize:'2.4rem', fontWeight:'bold'}
    }

    const [containerStyle, setContainerStyle] = useState({});
    const [headerStyle, setHeaderStyle] = useState({});
    const [headerInner, setHeaderInner] = useState({})
    const [headerParagraph, setHeaderParagraph] = useState({});

    useEffect(()=>{
        setHeaderStyle(styles.header)
        setContainerStyle(styles.container)
        setHeaderInner(styles.header__inner)
        setHeaderParagraph(styles.header__p)
    },[])

    return (
        <header style={headerStyle} className="header">
            <div style={containerStyle} className="container">
                <div style={headerInner} className="header__inner">
                    <div className="logo logo--aviva">
                        {
                            brandingInfo.BrandLogo !== undefined && brandingInfo.BrandLogo === 'https://av-mylogo.invalid' ?
                                <img src={avivaLogo} alt="Aviva Logo" /> :
                                <></>
                        }
                    </div>
                    <div className="logo logo--iprs">
                        <img src={iprsLogo} alt="IPRS Logo" />
                    </div>
                </div>
                <p style={headerParagraph} className="header__p">Your recovery starts here</p>
            </div>
        </header>
    )
}

export default DefaultHeader;