import React, {useState, useEffect} from 'react';

import PaymentForm from './PaymentForm';
// import {PaymentForm} from 'iprs-react-library';
import PaymentSuccess from './PaymentSuccess';
import PaymentUnsuccessful from './PaymentUnsuccessful'
import './styles.css';

const FormWrapper = ({
        api, 
        setDisplayNotice, 
        nextURL, 
        brandingInfo
    }) => {

    const [paymentResult, setPaymentResult] = useState('');
    const [token, setToken] = useState('')

    // Retrieves token from query in URL and sets token state when this component is loaded
    useEffect(()=>{
        let t = api.getToken();
        setToken(t);
    },[api])

    // displayNotice state changes depending on the state of paymentResult
    useEffect(()=>{
        if(paymentResult !== ''){
            setDisplayNotice(false);
        } else {
            setDisplayNotice(true)
        }
    },[paymentResult, setDisplayNotice])

    return (
        <>
            {/* 
                When paymentResult is empty string which is it's initial state when this component is loaded, 
                this ternary operator will display PaymentForm component.
                
                When user clicks payment button after filling out all the fields, payment API request
                will be made and upon it's response, paymentResult state will change which determines whether to select
                PaymentSuccess or PaymentUnsuccessful component based on paymentResult state value.
                
                PaymentUnsuccessful will have setPaymentResult prop passed down, which is used to allow user to reset 
                the paymentResult to empty string which will make the displayed component to be switch back to 
                PaymentForm component.
            */}
            <div className='container payment'>
                {paymentResult === '' ? ( 
                    <PaymentForm api={api} setPaymentResult={setPaymentResult} token={token} />
                ) : (paymentResult === 'Success') ? (
                    <PaymentSuccess nextURL={nextURL} api={api} token={token}/>
                ) : (
                    <PaymentUnsuccessful setPaymentResult={setPaymentResult} brandingInfo={brandingInfo}/>
                )} 
            </div>
        </>
    )
}

export default FormWrapper;