import React from 'react'
import Logo from '../../../images/logos/iprshealth.svg';

const ReferralFormHeader = () => {
  return (
    <header className="container">
        <header className="topHeader main__top">
            <div className="main__top-logo">
            <img src={Logo} alt="IPRS Health" />
            </div>
        </header>
        
        <div className="header__supplementary">
        </div>
    </header>
  )
}

export default ReferralFormHeader