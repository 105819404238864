export const synthetix = () => {
    const id = "getsyn";
    const scriptLocation = document.getElementById(id);
  
    if (!scriptLocation) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://cdn.synthetix.com/penfield/get_synthetix.min.js?applicationkey=09eb27883c6b2441a1265146337f0a63&consumerkey=d55c9df3224767eca9631494cb1d7bab";
        script.id = id;
        script.async = true;
        script.onload = () => {
            console.log('loading synthetix()', window.synthetix());
        };
        script.onerror = (message, source, lineno, colno, error) => {
            console.log("chat script failed");
        };
        document.body.appendChild(script);
    }
  };